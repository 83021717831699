<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <fep-switchmenu :currentviewlankey="currentviewlankey" @switchmenu="switchmenu"/>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlitex':'vcontentfull'):'vcontentfull'">
                <a-row type="flex" :gutter="{xs:0,sm:30,md:30,lg:30}">
                    <a-col :xs="24" :sm="24" :md="12" :lg="6">
                        <a-card :bodyStyle="{padding:'15px 20px'}" :style="{margin:'5px 0 15px 0'}">
                            <div class="fright">
                                <a-avatar :size="48" style="background-color:#f54350">
                                    <template #icon>
                                        <UserOutlined/>
                                    </template>
                                </a-avatar>
                            </div>
                            <div class="fleft taleft">
                                <div>
                                    <span class="titip">{{$langsmgr('langkey.component.dashboard.overview.expires')}}</span>
                                </div>
                                <div>
                                    <span v-if="userservpack.packid" class="titsubscr">{{userservpack.expiresed}}</span>
                                    <span v-else class="titsubscr">{{$langsmgr('langkey.component.dashboard.overview.nodata')}}</span>
                                </div>
                                <div class="tibtm">
                                    <span v-if="userservpack.packid">{{$langsmgr('langkey.component.dashboard.subscribe.expiresshort',{"{0}":userservpack.expiresdays})}}</span>
                                    <span v-else>{{$langsmgr('langkey.component.dashboard.overview.nodata')}}</span>
                                </div>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="12" :lg="6">
                        <a-card :bodyStyle="{padding:'15px 20px'}" :style="{margin:'5px 0 15px 0'}">
                            <div class="fright">
                                <a-avatar :size="48" style="background-color:#fb7d40">
                                    <template #icon>
                                        <BarChartOutlined/>
                                    </template>
                                </a-avatar>
                            </div>
                            <div class="fleft taleft">
                                <div>
                                    <span class="titip">{{$langsmgr('langkey.component.dashboard.overview.flowused')}}</span>
                                </div>
                                <div>
                                    <span v-if="userservpack.packid" class="titsubscr">{{userservpack.flowused}} GB</span>
                                    <span v-else class="titsubscr">{{$langsmgr('langkey.component.dashboard.overview.nodata')}}</span>
                                </div>
                                <div class="tibtm">
                                    <span v-if="userservpack.packid">{{$langsmgr('langkey.component.dashboard.overview.flowtotal')}} {{userservpack.flowtotal}} GB</span>
                                    <span v-else>{{$langsmgr('langkey.component.dashboard.overview.flowtotal')}} {{$langsmgr('langkey.component.dashboard.overview.nodata')}}</span>
                                </div>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="12" :lg="6">
                        <a-card :bodyStyle="{padding:'15px 20px'}" :style="{margin:'5px 0 15px 0'}">
                            <div class="fright">
                                <a-avatar :size="48" style="background-color:#2dce9b">
                                    <template #icon>
                                        <TransactionOutlined/>
                                    </template>
                                </a-avatar>
                            </div>
                            <div class="fleft taleft">
                                <div>
                                    <span class="titip">{{$langsmgr('langkey.component.dashboard.overview.balance')}}</span>
                                </div>
                                <div>
                                    <span class="titsubscr">￥ {{userservpack.balance?userservpack.balance:'0.00'}}</span>
                                </div>
                                <div class="tibtm">
                                    <span>{{$langsmgr('langkey.component.dashboard.overview.popbalance')}} ￥ {{userservpack.popbalance?userservpack.popbalance:'0.00'}}</span>
                                </div>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="12" :lg="6">
                        <a-card :bodyStyle="{padding:'15px 20px'}" :style="{margin:'5px 0 15px 0'}">
                            <div class="fright">
                                <a-avatar :size="48" style="background-color:#11a2ef">
                                    <template #icon>
                                        <PieChartOutlined/>
                                    </template>
                                </a-avatar>
                            </div>
                            <div class="fleft taleft">
                                <div>
                                    <span class="titip">{{$langsmgr('langkey.component.dashboard.overview.orderopened')}}</span>
                                </div>
                                <div>
                                    <span v-if="userservpack.orderopened" class="titsubscr">{{userservpack.orderopened}}</span>
                                    <span v-else class="titsubscr">0</span>
                                </div>
                                <div class="tibtm">
                                    <span v-if="userservpack.orderclosed">{{$langsmgr('langkey.component.dashboard.overview.orderclosed')}} {{userservpack.orderclosed}}</span>
                                    <span v-else>{{$langsmgr('langkey.component.dashboard.overview.orderclosed')}} 0</span>
                                </div>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="{xs:0,sm:30,md:30,lg:30}">
                    <a-col :xs="24" :sm="24" :md="12" :lg="12">

                        <a-card :title="$langsmgr('langkey.component.dashboard.mysubscribe')" :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'6px'}" :headStyle="{backgroundColor:'#f8f9fc'}">
                            <a-skeleton active :loading="loading_subscribe">
                                <div v-if="usersubscribed">
                                    <p>
                                        <h2><strong>{{userservpack.packname}}</strong></h2>
                                    </p>
                                    <p class="expiresinfo">
                                        <span>{{$langsmgr('langkey.component.dashboard.subscribe.expiresinfo',{"{0}":userservpack.expiresed,"{1}":userservpack.expiresdays})}}</span>
                                        <span v-if="userservpack.expiresed>0x1e">{{$langsmgr('langkey.component.dashboard.subscribe.resetinfo',{"{0}":userservpack.resetdays})}}</span>
                                    </p>
                                    <p class="progressline">
                                        <a-progress :percent="userflowpercent" status="active" :show-info="false" size="small"></a-progress>
                                        <strong>{{$langsmgr('langkey.component.dashboard.subscribe.flowinfo',{"{0}":userservpack.flowused,"{1}":userservpack.flowtotal})}}</strong>
                                    </p>
                                </div>
                                <div v-else>
                                    <a class="empsubscr" href="javascript:;" @click="buypackage">
                                        <div class="addsubscr"><strong><PlusOutlined/></strong></div>
                                        <div class="buysubscr">{{$langsmgr('langkey.component.dashboard.buysubscribe')}}</div>
                                    </a>
                                </div>
                            </a-skeleton>
                        </a-card>

                        <a-card :title="$langsmgr('langkey.component.dashboard.fastway')" :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'6px'}" :headStyle="{backgroundColor:'#f8f9fc'}" 
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a href="javascript:;" class="infoitem" @click="showSubscribe">
                                <div class="infoicon"><CheckCircleOutlined/></div>
                                <div class="infolinetit">{{$langsmgr('langkey.component.dashboard.fastway.subscribe')}}</div>
                                <div class="infolinedec">{{$langsmgr('langkey.component.dashboard.fastway.subscribe.intro')}}</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="infoitem" @click="rechargeservpack">
                                <div class="infoicon"><PlusCircleOutlined/></div>
                                <div class="infolinetit">{{$langsmgr('langkey.component.dashboard.fastway.recharge')}}</div>
                                <div class="infolinedec">{{$langsmgr('langkey.component.dashboard.fastway.recharge.intro')}}</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="infoitem" @click="viewdocument">
                                <div class="infoicon"><ReadOutlined/></div>
                                <div class="infolinetit">{{$langsmgr('langkey.component.dashboard.fastway.viewdoc')}}</div>
                                <div class="infolinedec">{{$langsmgr('langkey.component.dashboard.fastway.viewdoc.intro')}}</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="infoitem" @click="gotickets">
                                <div class="infoicon"><QuestionOutlined/></div>
                                <div class="infolinetit">{{$langsmgr('langkey.component.dashboard.fastway.question')}}</div>
                                <div class="infolinedec">{{$langsmgr('langkey.component.dashboard.fastway.question.intro')}}</div>
                            </a>
                        </a-card>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="12" :lg="12">
                        <a-card :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'6px'}">
                            
                            <div v-for="ann in sysannounces">
                                <div>
                                    <span class="announcetip">{{$langsmgr('langkey.component.dashboard.announce')}}</span>
                                    <span class="pl10px" :style="{fontSize:'16px'}">{{ann.title}}</span>
                                </div>
                                <a-divider/>
                                <div v-html="ann.content">
                                </div>
                            </div>
                            <!-- <a-skeleton active :loading="loading_announce" :style="{padding:'20px'}">
                                <a-carousel autoplay>
                                    <a v-for="ann in sysannounces" href="javascript:;" @click="showAnnounce(ann)">
                                        <div>
                                            <span class="announcetip">{{$langsmgr('langkey.component.dashboard.announce')}}</span>
                                        </div>
                                        <div class="announcetit">
                                            <span>{{ann.title}}</span>
                                        </div>
                                        <div class="announcedate">{{ann.regtime}}</div>
                                    </a>
                                </a-carousel>
                            </a-skeleton> -->
                        </a-card>
                        <!-- <div :class="ismobile?'announceareamobile':'announcearea'">
                            <a-skeleton active :loading="loading_announce" :style="{padding:'20px'}">
                                <a-carousel autoplay>
                                    <a v-for="ann in sysannounces" href="javascript:;" @click="showAnnounce(ann)">
                                        <div>
                                            <span class="announcetip">{{$langsmgr('langkey.component.dashboard.announce')}}</span>
                                        </div>
                                        <div class="announcetit">
                                            <span>{{ann.title}}</span>
                                        </div>
                                        <div class="announcedate">{{ann.regtime}}</div>
                                    </a>
                                </a-carousel>
                            </a-skeleton>
                        </div> -->
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>
                <a-modal v-model:open="announceshowstate" width="700px" :footer="null" 
                    :title="$langsmgr('langkey.component.dashboard.announce')">
                    <div>
                        <h2>{{drawerAnnounceTitle}}</h2>
                    </div>
                    <div v-html="drawerAnnounceContent"></div>
                </a-modal>
                <a-modal v-model:open="subscrmodalshowstate" width="350px" :closable="false" centered :header="null" :footer="null" :style="{borderRadius:'2px'}">
                    <subscrctls @copycallback="copysubscr" @qrcodecallback="switchqrcode" 
                        @importcfwcallback="importcfw" @importshadowrocketcallback="importshadowrocket" @downloadconfigcallback="downloadconfig"
                        @viewdoccallback="viewdocument"/>
                </a-modal>
                <a-modal v-model:open="qrcodemodalshowstate" width="300px" :closable="false" centered :header="null" :footer="null" :style="{borderRadius:'2px'}">
                    <div class="qrcodearea">
                        <vue-qr :text="userservpack.subscribeuri" :margin="0" :size="128"/>
                    </div>
                    <div class="qrcodeintro">
                        {{$langsmgr('langkey.component.dashboard.subscribe.qrcodetips')}}
                    </div>
                </a-modal>
                <a-drawer v-if="ismobile" placement="bottom" height="255px" :open="subscrdrawershowstate" :closable="false" :maskClosable="true" :title="null" @close="subscrdrawerClose">
                    <subscrctls 
                        @copycallback="copysubscr"
                        @qrcodecallback="switchqrcode" 
                        @importcfwcallback="importcfw" 
                        @importshadowrocketcallback="importshadowrocket"
                        @viewdoccallback="viewdocument" 
                        @onchangeroute="changeroute" 
                        @downloadconfigcallback="downloadconfig"/>
                </a-drawer>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,ReadOutlined,
    CloudDownloadOutlined,HistoryOutlined,QuestionCircleOutlined,PlusOutlined,
    UserOutlined,BarChartOutlined,TransactionOutlined,PieChartOutlined,
    CheckCircleOutlined,PlusCircleOutlined,QuestionOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted, onUnmounted } from 'vue'
import subscrctls from './subscrctls.vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'
import {message} from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

const {toClipboard} = useClipboard()


const currentviewlankey = ref('langkey.menuitem.dashboard')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const usersubscribed = ref(false)
const userservpack = ref({
    subscribeid: null,
    subscribeuri: null,
    packid: null,
    planid: null,
    packname: null,
    expiresed: null,
    expiresdays: null,
    resetdays: null,
    flowused: null,
    flowtotal: null,
    balance: null,
    popbalance: null,
    orderopened: null,
    orderclosed: null
})
const userflowpercent = ref(0)

const loading_subscribe = ref(true)
const loading_announce = ref(true)

const btmpanelheight = ref('310px')

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties;
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())
const announceshowstate = ref(false)
const subscrmodalshowstate = ref(false)
const subscrdrawershowstate = ref(false)
const subscrmodal = ref(null)
const qrcodemodalshowstate = ref(false)
const sysannounces = ref([])

const drawerAnnounceTitle = ref('')
const drawerAnnounceContent = ref('')

const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }
const showAnnounce = (announce) => {
    drawerAnnounceTitle.value = announce.title
    drawerAnnounceContent.value = announce.content

    announceshowstate.value = true
}
const showSubscribe = () => {
    if(!ismobile.value) subscrmodalshowstate.value = true
    else {
        subscrdrawershowstate.value = true
    }
}
const subscrdrawerClose = () => {
    subscrdrawershowstate.value = false
}
const copysubscr = async() => {
    try{ 
        await toClipboard(userservpack.value.subscribeuri)
        message.success(gconfig.$langsmgr('langkey.component.dashboard.subscribe.copysucc'))
    } catch { message.error(gconfig.$langsmgr('langkey.component.dashboard.subscribe.copyfail')) }
}

const switchqrcode = () => {
    qrcodemodalshowstate.value = true
}

const importcfw = () => {
    window.open(`clash://install-config?url=${encodeURIComponent(userservpack.value.subscribeuri)}`,'_self')
}
const importshadowrocket = () => {
    window.open(`shadowrocket://add/sub://${Base64.encode(userservpack.value.subscribeuri)}/?remark=${encodeURIComponent(gconfig.$sitename)}`,'_self')
}

const viewdocument = () => {
    ginstance.appContext.config.globalProperties.$uimenuselectedkey = '15'
    location.href="#/documents"
}
const buypackage = () => {
    ginstance.appContext.config.globalProperties.$uimenuselectedkey = "10"
    location.href="#/plans"
}
const rechargeservpack = () => {
    if(userservpack.value.packid){
        ginstance.appContext.config.globalProperties.$uimenuselectedkey = "11"
        location.href=`#/plan?p=${userservpack.value.packid}`
    }else buypackage()
}
const gotickets = () => {
    ginstance.appContext.config.globalProperties.$uimenuselectedkey = "13"
    location.href="#/tickets"
}

const downloadconfig = () => {
    var __subid = userservpack.value.subscribeid
    //console.log(userservpack.value)
    if(__subid){
        window.open(`${gconfig.$backendbase}/dashboard/v3/dlconfig?id=${__subid}${!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X.*/)?'':'&type=clash'}`,'_blank')
    }else{
        message.error('目前没有可用的订阅，请先购买订阅')
    }
}


onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/global/v4/fingerprint`,{
        params: {r:Math.random()}
    });

    axios.get(`${gconfig.$backendbase}/dashboard/v3/announces`,{
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response.data.data.result && response.data.data.announces.length>0x00) {
            for(var i = 0x00; i < response.data.data.announces.length; i++)
                sysannounces.value.push({
                    id:response.data.data.announces[i].id,
                    title:response.data.data.announces[i].title,
                    content:response.data.data.announces[i].content,
                    regtime:response.data.data.announces[i].regtime,
                })
        }
        loading_announce.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })

    axios.get(`${gconfig.$backendbase}/dashboard/v3/useroverview`, {
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response.data.data.result) {
            usersubscribed.value = response.data.data.servpack != null
            if(usersubscribed.value) {
                gconfig.$vusubscrid = userservpack.value.subscribeid = response.data.data.subscribeid
                gconfig.$storage.set(gconfig.$storagekey_fep_vusubscrid, gconfig.$vusubscrid)

                userservpack.value.subscribeuri = response.data.data.subscribeuri
                userservpack.value.packid = response.data.data.servpackid
                userservpack.value.packname = decodeURIComponent(response.data.data.servpack)
                userservpack.value.expiresed = response.data.data.expiresdate
                userservpack.value.expiresdays = response.data.data.expiresdays
                userservpack.value.resetdays = response.data.data.resetdays
                userservpack.value.flowused = response.data.data.flowused.toFixed(0x02)
                userservpack.value.flowtotal = response.data.data.flowtotal.toFixed(0x02)
                userservpack.value.balance = response.data.data.balance.toFixed(0x02)
                userservpack.value.popbalance = response.data.data.popbalance.toFixed(0x02)
                userservpack.value.orderopened = response.data.data.orderopened
                userservpack.value.orderclosed = response.data.data.orderclosed

                userflowpercent.value = (userservpack.value.flowused / userservpack.value.flowtotal)*100
                //console.log(userflowpercent.value)
            }
        }
        loading_subscribe.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
})

const changeroute = (sign) => {
    if(ismobile.value){
        btmpanelheight.value = sign>0x00?`${(200+sign*50)}px`:'310px'
    }
}

onUnmounted(()=>{
    //console.log("dashboard unmounted")
})


</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.announcearea{
    height: 248px;
    background: gray;
    background-size: 100% auto;
    background-position: 10% 70%;
}
.announceareamobile{
    height: 248px;
    background: gray;
    background-size: auto 150%;
    background-position: 50% 80%;
}
:deep(.slick-slide) {
    text-align: left;
    height: 248px;
    overflow: hidden;
    padding: 22px 20px;
}

.expiresinfo {
    color:#666666;
}
.progressline {
    line-height:5px;
    color:#666666;
}
.infoitem{
    display:block;
    margin:0;
    padding:20px;
    text-align: left;
}
.infoitem:hover{
    background-color: #f6f6f6;
}
.infocnt{
    margin:0;
    padding:0;
}
.infoicon {
    float:right;
    font-size:32px;
    color:#999999;
}
.infolinetit {
    font-size:16px;
    color:#333333;
}
.infolinedec {
    font-size:12px;
    color:#999999;
}
.qrcodearea {
    padding: 15px 0;
    text-align: center;
}
.qrcode {
    width:128px;
    height:128px;
    border:0;
    background-color:#f0f0f0;
}
.qrcodeintro{
    font-size:12px;
    text-align: center;
    color:#999999;
}
.empsubscr{
    display: block;
    text-align:center;
    color:#999999;
}
.empsubscr:hover{
    color:#666666;
}
.addsubscr{
    font-size:40px;
    font-weight: bolder;
}
.taleft{
    text-align: left;
}
.taleft p {
    margin:0;
    padding:0;
}
.titip{
    font-weight: bold;
    color:#999;
}
.tibtm{
    color:#999;
    padding-top:15px;
}
.titsubscr{
    font-size: 20px;
    font-weight: bold;
    color:#333;
}
</style>