<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <fep-switchmenu :currentviewlankey="currentviewlankey" @switchmenu="switchmenu"/>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-card :title="purchaseinfo.name" :style="{borderRadius:'6px'}" class="pkginfo" :headStyle="{fontSize:'20px',fontWeight:'bold'}">
                    <a-skeleton active :loading="purchaseinfo.loading_description">
                        <div class="pkgintro" v-html="purchaseinfo.description"></div>
                    </a-skeleton>
                </a-card>
                <a-card :title="$langsmgr('langkey.component.recharge.settleup')" :style="{borderRadius:'6px', marginTop:'20px'}" 
                    class="pkginfo" :headStyle="{fontSize:'20px',fontWeight:'bold'}">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="12" :lg="12">
                            <div style="font-size:14px;font-weight:bold;color:#32325dc0;padding-bottom:10px;">订阅类型</div>
                            <div>
                                <a-segmented v-model:value="purchaseinfo.selectedplansign" :options="purchaseinfo.plans"
                                    @change="planchange">
                                    <template #label="{payload}">
                                        <div :style="{padding:'5px 15px'}">
                                            <div style="font-size:16px;font-weight:bold;color:#32325d;">
                                                {{payload.display}}
                                            </div>
                                            <div style="font-size:16px;padding-top:10px;">
                                                ￥{{payload.price}}
                                            </div>
                                        </div>
                                    </template>
                                </a-segmented>
                            </div>
                            <div style="font-size:14px;font-weight:bold;color:#32325dc0;padding-bottom:10px;padding-top:30px">支付类型</div>
                            <div>
                                <a-segmented v-model:value="purchaseinfo.paytype" :options="presetpaytypes" @change="planchange">
                                    <template #label="{payload}">
                                        <div :style="{padding:'5px 15px'}">
                                            <div style="font-size:14px;font-weight:bold;color:#32325d;">
                                                {{payload.display}}
                                            </div>
                                        </div>
                                    </template>
                                </a-segmented>
                            </div>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="12" :lg="12">
                            <div :style="{padding:'0 20px'}">
                                <a-skeleton active :loading="purchaseinfo.loading_settleinfo" :style="{padding:'20px'}">
                                    <a-input-search class="darkinput" v-model:value="purchaseinfo.promocode" :placeholder="$langsmgr('langkey.component.recharge.promoquest')" size="large" :style="{backgroundColor:'transparent',borderRadius:'2px'}">
                                        <template #enterButton>
                                            <a-button :loading="purchaseinfo.loading_couponuse" type="primary" @click="usepromocode">{{$langsmgr('langkey.component.recharge.promocheck')}}</a-button>
                                        </template>
                                    </a-input-search>
                                </a-skeleton>
                            </div>
                            <a-divider/>
                            <div>
                                <div class="settleviewcontent">
                                    <span class="settleitemb purname">{{purchaseinfo.name}}&nbsp;&nbsp;{{'['+purchaseinfo.selectedplanname+']'}}</span>
                                    <span class="settleitemb purprice">￥ {{purchaseinfo.selectedplanprice}}</span>
                                </div>
                                <div v-if="purchaseinfo.promocodeid" class="settleviewcontent">
                                    <span class="settleitemb purname">{{$langsmgr('langkey.component.recharge.promocode')}}</span>
                                    <span class="settleitemb purprice">-￥ {{purchaseinfo.promocutoffvalue}}</span>
                                </div>
                                <div class="settleviewcontent" style="padding-bottom:0;">
                                    <span class="settletotalb purname">{{$langsmgr('langkey.component.recharge.settletotal')}} ￥ {{purchaseinfo.settleamount}} CNY</span>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                    <a-divider/>
                    <div>
                        <div class="settleviewcontent clearfix fright">
                            <a-button size="large" type="primary" :loading="purchaseinfo.loading_settleup" @click="settleup">
                                <CheckCircleOutlined v-if="!purchaseinfo.loading_settleup"/>
                                {{$langsmgr('langkey.component.recharge.settleup2')}}
                            </a-button>
                        </div>
                    </div>
                </a-card>
                <a-row v-if="false">
                    <a-col :span="winwidth>winwidthmodelimit&&!ismobile?16:24">
                        <a-card :title="$langsmgr('langkey.component.recharge.paycircles')" :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}" 
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a-skeleton active :loading="purchaseinfo.loading_plans" :style="{padding:'20px'}">
                                <div>
                                    <a href="javascript:;" class="puritem">
                                        <span :class="winwidth>winwidthmodelimit&&!ismobile?'purname':'purprice'">
                                            <a-radio-group v-model:value="purchaseinfo.selectedplansign" :id="purchaseinfo.selectedplanid"
                                                :options="purchaseinfo.plans" option-type="button" button-style="solid"
                                                @change="planchange">
                                            </a-radio-group>
                                        </span>
                                        <span :class="winwidth>winwidthmodelimit&&!ismobile?'purprice':'purname'">
                                            ￥ {{purchaseinfo.selectedplanprice}}
                                        </span>
                                    </a>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                            </a-skeleton>
                        </a-card>
                        <a-card :title="$langsmgr('langkey.component.recharge.payments')" :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}" 
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a-skeleton active :loading="purchaseinfo.loading_plans" :style="{padding:'20px'}">
                                <div>
                                    <a href="javascript:;" class="puritem">
                                        <span :class="winwidth>winwidthmodelimit&&!ismobile?'purname':'purprice'">
                                            <a-radio-group v-model:value="purchaseinfo.paytype" button-style="solid" option-type="button">
                                                <!-- <a-radio-button value='usdt' disabled>{{$langsmgr('langkey.component.recharge.payment.usdt')}}</a-radio-button> -->
                                                <a-radio-button value='wxpay'>{{$langsmgr('langkey.component.recharge.payment.wxpay')}}</a-radio-button>
                                                <a-radio-button value='alipay'>{{$langsmgr('langkey.component.recharge.payment.alipay')}}</a-radio-button>
                                            </a-radio-group>
                                        </span>
                                    </a>
                                </div>
                            </a-skeleton>
                        </a-card>
                    </a-col>
                    <a-col :span="winwidth>winwidthmodelimit&&!ismobile?8:24">
                        <a-card class="rowcard bgblack" :class="winwidth>winwidthmodelimit&&!ismobile?'pleft':null"
                            :bordered="false" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:0}">
                            <div class="settleviewhead">
                                {{$langsmgr('langkey.component.recharge.billinginfo')}}
                            </div>
                            <a-skeleton active :loading="purchaseinfo.loading_billinginfo" :style="{padding:'20px 20px 0 20px'}">
                                <div class="settleviewcontent">
                                    <span class="purname">{{$langsmgr('langkey.component.recharge.billingnum')}}</span>
                                    <span class="purprice">{{purchaseinfo.billno}}</span>
                                </div>
                                <div class="settleviewcontent">
                                    <span class="purname">{{$langsmgr('langkey.component.recharge.billingcreatetime')}}</span>
                                    <span class="purprice">{{purchaseinfo.createtime}}</span>
                                </div>
                            </a-skeleton>
                            <a-divider :style="{backgroundColor:'#cccccc',width:'90%',margin:'20px 0'}"/>
                            <div class="settleviewhead" style="padding-top:0;">
                                {{$langsmgr('langkey.component.recharge.settleinfo')}}
                            </div>
                            <a-skeleton active :loading="purchaseinfo.loading_settleinfo" :style="{padding:'20px'}">
                                <div class="settleviewcontent">
                                    <span class="settleitem purname">{{purchaseinfo.name}}&nbsp;&nbsp;{{'['+purchaseinfo.selectedplanname+']'}}</span>
                                    <span class="settleitem purprice">￥ {{purchaseinfo.selectedplanprice}}</span>
                                </div>
                                <div v-if="purchaseinfo.promocodeid" class="settleviewcontent">
                                    <span class="settleitem purname">{{$langsmgr('langkey.component.recharge.promocode')}}</span>
                                    <span class="settleitem purprice">-￥ {{purchaseinfo.promocutoffvalue}}</span>
                                </div>
                                <div class="settleviewcontent">
                                    <a-divider :style="{backgroundColor:'#666666',margin:'10px 0 0 0'}"/>
                                </div>
                                <div class="settleviewcontent" style="padding-bottom:0;padding-top:'5px'">
                                    <span class="settletotal purname">{{$langsmgr('langkey.component.recharge.settletotal')}}</span>
                                </div>
                                <div class="settleviewcontent clearfix">
                                    <span class="finaltotal purname">￥ {{purchaseinfo.settleamount}} CNY</span>
                                </div>
                                <div class="settleviewcontent clearfix">
                                    <a-button :block="true" size="large" type="primary" :loading="purchaseinfo.loading_settleup" @click="settleup">
                                        <CheckCircleOutlined v-if="!purchaseinfo.loading_settleup"/>
                                        {{$langsmgr('langkey.component.recharge.settleup')}}
                                    </a-button>
                                </div>
                            </a-skeleton>
                        </a-card>
                        <a-card class="rowcard bgblack" :class="winwidth>winwidthmodelimit&&!ismobile?'pleft':null" :bordered="false" :style="{borderRadius:'2px',marginTop:0}">
                            <a-skeleton active :loading="purchaseinfo.loading_settleinfo" :style="{padding:'20px'}">
                                <a-input-search class="darkinput" v-model:value="purchaseinfo.promocode" :placeholder="$langsmgr('langkey.component.recharge.promoquest')" size="large" :style="{backgroundColor:'transparent',borderRadius:'2px'}">
                                    <template #enterButton>
                                        <a-button :loading="purchaseinfo.loading_couponuse" type="primary" @click="usepromocode">{{$langsmgr('langkey.component.recharge.promocheck')}}</a-button>
                                    </template>
                                </a-input-search>
                            </a-skeleton>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CheckCircleOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'


const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())

const currentviewlankey = ref('langkey.menuitem.recharge')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const paycate = ref('0')
const paytype = ref('alipay')

const presetpaytypes = ref([
    // {
    //     value: "usdt",
    //     disabled: true,
    //     payload:{
    //         display: gconfig.$langsmgr('langkey.component.recharge.payment.usdt'),
    //     }
    // },
    {
        value: "wxpay",
        payload:{
            display: gconfig.$langsmgr('langkey.component.recharge.payment.wxpay'),
        }
    },
    {
        value: "alipay",
        payload:{
            display: gconfig.$langsmgr('langkey.component.recharge.payment.alipay'),
        }
    }
])

const purchaseinfo = ref({
    packid: null,
    name: null,
    description: null,
    loading_description: ref(true),
    loading_plans: ref(true),
    loading_billinginfo: ref(true),
    loading_settleinfo: ref(true),
    loading_couponuse: ref(false),
    loading_settleup: ref(false),
    selectedplanid: null,
    selectedplanname: null,
    selectedplansign: null,
    selectedplanprice: null,
    billno: null,
    createtime: null,
    paytype: 'alipay',
    usepromo: false,
    promocodeid: null,
    promocode: null,
    promocutoffvalue: null,
    settleamount: null,
    plans: []
})

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    // console.log("packid: ", gconfig.$querystring('p'))
    // console.log("planid: ", gconfig.$querystring('s'))

    loadservpack()
    genbillingdata()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const loadservpack = () => {
    purchaseinfo.value.packid = gconfig.$querystring("p")
    axios.get(`${gconfig.$backendbase}/setmeals/v3/servpackdetail`,{
        params:{
            packid: purchaseinfo.value.packid,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result) {
            var __servpack = resp.data.data
            var __selectedplanid = gconfig.$querystring("s")
            purchaseinfo.value.name = decodeURIComponent(__servpack.name)
            purchaseinfo.value.description = decodeURIComponent(__servpack.remark)
            if(__servpack.plans&&__servpack.plans.length>0x00){
                for(var i = 0x00; i<__servpack.plans.length; i++){
                    var __plan = __servpack.plans[i]
                    purchaseinfo.value.plans.push({
                        id: __plan.id,
                        //label: decodeURIComponent(__plan.display),
                        display: decodeURIComponent(__plan.display),
                        value: __plan.sign,
                        payload: {
                            display: decodeURIComponent(__plan.display),
                            price: __plan.price.toFixed(0x02)
                        },
                        isdefault: __plan.isdefault,
                        price: __plan.price.toFixed(0x02),
                        duration: __plan.durationlimit,
                        state: __plan.state
                    })
                    if(__selectedplanid) {
                        if(__plan.id == __selectedplanid){
                            purchaseinfo.value.selectedplanid = __plan.id
                            purchaseinfo.value.selectedplanname = decodeURIComponent(__plan.display)
                            purchaseinfo.value.selectedplansign = __plan.sign
                            purchaseinfo.value.settleamount = purchaseinfo.value.selectedplanprice = __plan.price.toFixed(0x02)
                        }
                    }else if(__plan.isdefault) {
                        purchaseinfo.value.selectedplanid = __plan.id
                        purchaseinfo.value.selectedplanname = decodeURIComponent(__plan.display)
                        purchaseinfo.value.selectedplansign = __plan.sign
                        purchaseinfo.value.settleamount = purchaseinfo.value.selectedplanprice = __plan.price.toFixed(0x02)
                    }
                }
                if(!purchaseinfo.value.selectedplanprice){
                    purchaseinfo.value.selectedplanid = purchaseinfo.value.plans[0x00].id
                    purchaseinfo.value.selectedplanname = purchaseinfo.value.plans[0x00].label
                    purchaseinfo.value.selectedplansign = purchaseinfo.value.plans[0x00].sign
                    purchaseinfo.value.settleamount = purchaseinfo.value.selectedplanprice = purchaseinfo.value.plans[0x00].price
                }
            }else{
                purchaseinfo.value.settleamount = purchaseinfo.value.selectedplanprice = __servpack.price
            }
        }else{
            message.error(gconfig.$langsmgr('langkey.component.recharge.msg.planoutstock'))
            location.href='/#/plans'
        }
        purchaseinfo.value.loading_description=false
        purchaseinfo.value.loading_plans = false
        purchaseinfo.value.loading_settleinfo = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err,err=>{
            message.error(gconfig.$langsmgr('langkey.component.recharge.msg.planexpired'))
            location.href='/#/plans'
        })
    })
}

const genbillingdata = () => {
    axios.get(`${gconfig.$backendbase}/recharge/v3/generatebilling`,{
        params:{
            bn: gconfig.$querystring("bn") ? gconfig.$querystring("bn") : null,
            r:Math.random()
        },
        headers:gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result){
            purchaseinfo.value.billno = resp.data.data.billingid
            purchaseinfo.value.createtime = resp.data.data.billingdatetime
        }
        purchaseinfo.value.loading_billinginfo = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

const planchange = e => {
    //var id = $(e.nativeEvent.srcElement.parentElement.parentElement.parentElement).attr("id")
    console.log(e)
    for(var i = 0x00; i < purchaseinfo.value.plans.length;i++){
        if(e == purchaseinfo.value.plans[i].value){
            purchaseinfo.value.selectedplanid = purchaseinfo.value.plans[i].id
            purchaseinfo.value.selectedplanname = purchaseinfo.value.plans[i].display
            purchaseinfo.value.settleamount = purchaseinfo.value.selectedplanprice = purchaseinfo.value.plans[i].price
            break
        }
    }
    purchaseinfo.value.promocodeid=null
    purchaseinfo.value.promocode=null

}

const usepromocode = () => {
    if(!purchaseinfo.value.promocode) return
    purchaseinfo.value.loading_couponuse = true
    axios.get(`${gconfig.$backendbase}/coupon/v3/checkcouponcode`,{
        params:{
            couponcode: purchaseinfo.value.promocode,
            packid: purchaseinfo.value.packid,
            planid: purchaseinfo.value.selectedplanid,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result) {
            purchaseinfo.value.promocodeid = resp.data.data.couponid
            switch(resp.data.data.type){
                case 0x00:
                    purchaseinfo.value.promocutoffvalue = resp.data.data.value.toFixed(0x02)
                    purchaseinfo.value.settleamount = (purchaseinfo.value.selectedplanprice - purchaseinfo.value.promocutoffvalue).toFixed(0x02)
                    break
                case 0x01:
                    purchaseinfo.value.promocutoffvalue = (purchaseinfo.value.settleamount * (1 - resp.data.data.value)).toFixed(0x02)
                    purchaseinfo.value.settleamount = (purchaseinfo.value.selectedplanprice - purchaseinfo.value.promocutoffvalue).toFixed(0x02)
                    break
            }
            if(purchaseinfo.value.settleamount<0x00) purchaseinfo.value.settleamount = 0;
        }else{
            switch(resp.data.data.code){
                case "rsc/20031":
                    message.error(gconfig.$langsmgr('langkey.component.recharge.msg.promoexpired'))
                    break;
                case "rsc/20032":
                    message.error(gconfig.$langsmgr('langkey.component.recharge.msg.promounmatch'))
                    break;
            }
        }
        purchaseinfo.value.loading_couponuse = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

const settleup = () => {
    //console.log(purchaseinfo.value.promocodeid)

    // axios.get(`${gconfig.$backendbase}/billing/v3/checkbillingopened`,{
    //     params:{
    //         r: Math.random()
    //     },
    //     headers: gconfig.$getauthheaders()
    // }).then(resp=>{
    //     purchaseinfo.value.loading_settleup=true
    //     if(!resp.data.data.result){
    //         var __requri = `${gconfig.$backendbase}/recharge/v3/settleup?u=${gconfig.$getuserid()}&bn=${purchaseinfo.value.billno}&btime=${encodeURIComponent(purchaseinfo.value.createtime)}&type=plan&packid=${purchaseinfo.value.packid}&planid=${purchaseinfo.value.selectedplanid}&ptype=${purchaseinfo.value.paytype}${(purchaseinfo.value.promocodeid&&purchaseinfo.value.promocodeid.length>0x00?`&couponid=${purchaseinfo.value.promocodeid}`:'')}&callbackuri=${encodeURIComponent(`${location.origin}/#/order?bn=${purchaseinfo.value.billno}`)}&r=${Math.random()}`
    //         location.href=__requri
    //     }else{
    //         message.warning('当前仍有待支付订单，请不要重复下单')
    //         location.href="#/orders"
    //     }
    // }).catch(err=>{
    //     gconfig.$axiosErrorHandle(err)
    // })


    axios.post(`${gconfig.$backendbase}/recharge/v3/checksettlevalid`,{
        p: purchaseinfo.value.selectedplanid
    },{
        params: { r: Math.random() },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            axios.get(`${gconfig.$backendbase}/billing/v3/checkbillingopened`,{
                params:{
                    r: Math.random()
                },
                headers: gconfig.$getauthheaders()
            }).then(resp=>{
                purchaseinfo.value.loading_settleup=true
                if(!resp.data.data.result){
                    var __requri = `${gconfig.$backendbase}/recharge/v3/settleup?u=${gconfig.$getuserid()}&bn=${purchaseinfo.value.billno}&btime=${encodeURIComponent(purchaseinfo.value.createtime)}&type=plan&packid=${purchaseinfo.value.packid}&planid=${purchaseinfo.value.selectedplanid}&ptype=${purchaseinfo.value.paytype}${(purchaseinfo.value.promocodeid&&purchaseinfo.value.promocodeid.length>0x00?`&couponid=${purchaseinfo.value.promocodeid}`:'')}&callbackuri=${encodeURIComponent(`${location.origin}/#/order?bn=${purchaseinfo.value.billno}`)}&r=${Math.random()}`
                    location.href=__requri
                }else{
                    message.warning('当前仍有待支付订单，请不要重复下单')
                    location.href="#/orders"
                }
            }).catch(err=>{
                gconfig.$axiosErrorHandle(err)
            })
        }else{
            purchaseinfo.value.loading_settleup=false
            var retcode = parseInt(resp.data.data.code)
            if(retcode == -0x01){
                message.warning('当前选择的订阅类型不存在，请联系管理员获得帮助')
            }else if(retcode == -0x02){
                message.warning('管理员暂未开放免费订阅')
            }else if(retcode == -0x03){
                message.warning('该免费订阅类型本月已使用，请等待下个月再尝试')
            }else{
                message.warning('当前选择的订阅类型未知，请联系管理员获得帮助')
            }
        }
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })


}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.clearfix{
    clear:both;
}
.pkginfo{
    text-align: left;
}
.pkgintro{
    font-size:16px;
}
.pkgpropsinfo{
    font-weight: bold;
    color:#5e692d;
    background-color:#e6f0db;
    border-radius: 4px;
}
.pkgdetail{
    padding:10px 0;
}
.pkgdetail p{
    margin:0;
    padding:3px 0;
    line-height:25px;
    color:#666666;
}
.puritem{
    display:block;
    margin:0;
    height:70px;
    line-height:70px;
    font-size: 16px;
    padding:0 25px;
    text-align: left;
    color:#666666;
}
.purcells{
    height:30px;
    line-height:30px;
}
.purname{
    float:left;
}
.purprice{
    float: right;
}
.pleft{
    margin-left:20px;
}
.bgblack{
    background-color: #35383d;
    color:white;
}
.radius2{
    border-radius: 2px;
}

.darkinput{
    background-color:transparent;
    border: unset!important;
}
.darkinput::placeholder{
    color:#999999;
}
.settleviewhead{
    margin:0;
    padding:15px 20px 0 20px;
    font-size: 16px;
    font-weight: bolder;
}
.settleviewcontent{
    padding: 15px 20px;
    color:#cccccc;
}
.settleitem{
    color:white;
    font-size: 16px;
}
.settleitemb{
    color:#3a3a63;
    font-size: 16px;
}
.settletotal{
    font-size: 16px;
    color:#999999;
}
.settletotalb{
    font-size: 22px;
    padding-top:15px;
    color:#3a3a63;
    font-weight: bold;
}
.finaltotal{
    font-size: 35px;
    font-weight: bolder;
    color:white;
}
:deep(.ant-radio-button-wrapper) {
    margin-right:20px;
    border-radius: 2px;
}

:deep(.ant-radio-button-wrapper).span {
    padding:20px;
}
</style>