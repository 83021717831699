
import {message} from 'ant-design-vue'

var appinst = null;

const ismobile = function() {
    var __result = navigator.userAgent
        .match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return __result ? true : false;
}

const exit = function(appcontext) {
    var context = appcontext?appcontext:appinst
    context.config.globalProperties.$vusername = ""
    context.config.globalProperties.$vtoken = ""
    context.config.globalProperties.$storage.remove(context.config.globalProperties.$storagekey_fep_vsignname)
    context.config.globalProperties.$storage.remove(context.config.globalProperties.$storagekey_fep_vtoken)

    context.config.globalProperties.$uimenuselectedkey = "3"
    location.href="#/"
}

const getuserid = function() {
    appinst.config.globalProperties.$vuid = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vuid
        ).value
    return appinst.config.globalProperties.$vuid
}

const getusubscrid = function() {
    appinst.config.globalProperties.$vusubscrid = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vusubscrid
        ).value
    return appinst.config.globalProperties.$vusubscrid
}

const getusername = function() {
    appinst.config.globalProperties.$vusername = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vsignname
        ).value
    return appinst.config.globalProperties.$vusername
}

const getauthheaders = function() {
    appinst.config.globalProperties.$vtoken = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vtoken
        ).value
    return {Authorization:`Bearer ${appinst.config.globalProperties.$vtoken}`}
} 


const axiosErrorHandle = function(error, defcallback){
    
    if(error.code == "ERR_NETWORK"){
        message.error(appinst.config.globalProperties.$langsmgr('langkey.sysmsg.errnetwork'))
        location.href='#/'
    } else if(error.response && error.response.status == 401) {
        message.warning(appinst.config.globalProperties.$langsmgr('langkey.sysmsg.sessionexpired'))
        setTimeout(()=>{location.href='#/'},1000)
    } else if(defcallback && typeof defcallback == "function"){
        defcallback(error)
    }
}

const querystring = function(key){
    const after = window.location.hash.split('?',2)[1]
    if(after){
        const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
        const r = after.match(reg)
        return r!=null ? decodeURIComponent(r[2]) : null
    }
}



export default {
    install: (app) => {
        appinst = app

        //#region customize global properties

        app.config.globalProperties.$siteurl = 
            //"http://localhost:8080"
            "https://myym.cloud"
        app.config.globalProperties.$backendbase = 
            //"http://localhost:5001"
            "https://api.myym.cloud"
        app.config.globalProperties.$docspath = 
            //"http://localhost:8080"
            "https://myym.cloud"
        app.config.globalProperties.$subscruribase = "https://ymmy.b-cdn.net"
        app.config.globalProperties.$sitename = "羊毛云"
        app.config.globalProperties.$shortsitename = "全节点解锁流媒体、ChatGPT，价格实惠"
        app.config.globalProperties.$siteversion = "v3"
        app.config.globalProperties.$subtitle = "羊毛云"
        app.config.globalProperties.$shortsubtitle = ""
        app.config.globalProperties.$telegramgroup = "https://t.me/myymgp"
        app.config.globalProperties.$loginbgname = "loginbg.jpg"

        app.config.globalProperties.$storagekey_fep_vuid = "fep_vuid"
        app.config.globalProperties.$storagekey_fep_vusubscrid = "fep_vusubscrid"
        app.config.globalProperties.$storagekey_fep_vsignname = "fep_vsignname"
        app.config.globalProperties.$storagekey_fep_vtoken = "fep_vtoken"
        


        app.config.globalProperties.$vuid = ""
        app.config.globalProperties.$vusername = ""
        app.config.globalProperties.$vusubscrid = ""
        app.config.globalProperties.$vtoken = ""
        app.config.globalProperties.$vlangcode = "zhcn"

        app.config.globalProperties.$recaptchav3sitekey = "6LcQbu8oAAAAAPE4jfpgM4XJT6nhZ9p7Haab0r_9"
        app.config.globalProperties.$uimenuselectedkey = "3"
        app.config.globalProperties.$uimenucollapsed = false
        app.config.globalProperties.$menumappaths = {
            "0": "/",
            "1": "/login",
            "2": "/register",
            "3": "/dashboard",
            "4": "/profile",
            "5": "/orders",
            "6": "/order",
            "7": "/financess" ,
            "8": "/flowstats" ,
            "9": "/services" ,
            "10": "/plans" ,
            "11": "/plan" ,
            "12": "/activities" ,
            "13": "/tickets" ,
            "14": "/tos",
            "15": "/documents",
            "16": "/invite"
        }


        //#endregion

        app.config.globalProperties["$ismobile"] = ismobile;
        app.config.globalProperties["$axiosErrorHandle"] = axiosErrorHandle;
        app.config.globalProperties["$getauthheaders"] = getauthheaders;
        app.config.globalProperties["$getuserid"] = getuserid;
        app.config.globalProperties["$getusubscrid"] = getusubscrid;
        app.config.globalProperties["$getusername"] = getusername;
        app.config.globalProperties["$querystring"] = querystring;
        app.config.globalProperties["$exit"] = exit;

    }
}